import styled from "styled-components"
import media from "styled-media-query"

import Img from "gatsby-image"

export const PagesCaptureDescriptionBlock = styled.section`
  display: block;
  width: 60%;
  padding: 5rem;

  ${media.lessThan("large")`
    width: 100%;
    padding: 1rem;
  `}
`

export const PagesCaptureFormBlock = styled.section`
  display: block;
  width: 30%;
  background: var(--dark_orange);
  padding: 5rem;

  ${media.lessThan("large")`
    width: 100%;
    padding: 1rem;
  `}
`

export const PagesCaptureFormBlockTitle = styled.h2`
  line-height: 1.4;
  font-size: 2rem;
  color: var(--white);
  text-align: center;
`

export const PagesCaptureEbookImg = styled(Img)`
  width: 200px;
  height: 250px;
  margin: 1rem auto;
  object-fit: cover;
`

export const PagesCaptureDescriptionTitle = styled.h1`
  color: var(--orange);
  line-height: 1.4;
  font-size: 2.5rem;
  text-align: left;
  text-transform: uppercase;
  margin-bottom: 2.5rem;
`

export const PagesCaptureDescriptionText = styled.p`
  color: var(--grey);
  font-size: 1.2rem;
  line-height: 1.4;
  margin-bottom: 1.5rem;
`

export const PagesCaptureThanksBlockDescriptionFull = styled.section`
  display: block;
  width: 100%;
  padding: 5rem;

  ${media.lessThan("large")`
    width: 100%;
    padding: 1rem;
  `}
`
export const PagesCaptureThanksTitle = styled.h1`
  font-size: 2.5rem;
  text-align: center;
  text-transform: uppercase;
  line-height: 1.4;
  margin-bottom: 1.5rem;
`

export const PagesCaptureThanksText = styled.p`
  font-size: 1.75rem;
  line-height: 1.4;
  text-align: center;
  margin-bottom: 2rem;
`

export const PagesCaptureThanksIframeContainer = styled.div`
  margin: 0 auto;
  display: block;
  width: 960px;
  height: 480px;

  ${media.lessThan("large")`
    width: 300px;
    height: 150px;
  `}
`

export const PagesCaptureThanksIframe = styled.iframe`
  width: 100%;
  height: 100%;
  display: block;
  margin: 0 auto;
`

export const PagesCaptureDescriptionLink = styled.a`
  padding: 1.5rem;
  text-transform: uppercase;
  background-color: var(--red);
  border: none;
  display: block;
  color: var(--white);
  width: 70%;
  margin: 0 auto;
  text-align: center;
  font-size: 3rem;
  margin-top: 3rem;
  text-decoration: none;

  ${media.lessThan("large")`
    width: 100%;
  `}
`

export const PagesCaptureThanksBlockDescription = styled.section`
  display: block;
  width: 50%;
  padding: 5rem;

  ${media.lessThan("large")`
    width: 100%;
    padding: 1rem;
  `}
`

export const PagesCaptureThanksVideoBlock = styled.section`
  display: block;
  width: 50%;
  padding: 5rem;

  ${media.lessThan("large")`
    width: 100%;
    padding: 1rem;
  `}
`
export const PagesCaptureDownloadEbookButton = styled.a`
  cursor: pointer;
  display: block;
  width: 100%;
  border: none;
  text-transform: uppercase;
  text-align: center;
  padding: 1.5rem;
  color: var(--white);
  font-size: 1.2rem;
  background-color: var(--red);

  &:hover {
    text-decoration: underline;
  }
`
