import React from "react"
import "@fontsource/poppins"

import * as S from "./styled"

import GlobalStyles from "../../styles/global"
import Footer from "../Footer"

const LayoutPagesCapture = ({ children }) => {
  return (
    <>
      <GlobalStyles />
      <S.LayoutPagesWrapper>{children}</S.LayoutPagesWrapper>
      <Footer hasSocialMedia={true} />
    </>
  )
}

export default LayoutPagesCapture
