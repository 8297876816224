import styled from "styled-components"
import media from "styled-media-query"

import img from "../../images/back/background-site-ved-paginas.jpg"

export const LayoutPagesWrapper = styled.section`
  display: flex;
  padding: 0 5rem;
  background: url(${img}) bottom center;
  min-height: 70vh;

  ${media.lessThan("large")`
    flex-direction: column;
    padding: 1rem;
  `}
`
