import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import LayoutPagesCapture from "../../../../components/LayoutPagesCapture"
import SEO from "../../../../components/seo"

import * as S from "../../../../components/PagesCapture/styled"

const EbookPalavrasChavesNegativasPage = () => {
  const { ebook_palavras_chaves_negativas } = useStaticQuery(graphql`
    query {
      ebook_palavras_chaves_negativas: file(
        relativePath: { eq: "ebooks/mokup-ebook-palavras-chaves-negativas.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <LayoutPagesCapture>
      <SEO
        title="Ebook - Palavras Chaves Negativas"
        description="Ebook contendo as principais palavras chaves negativas para você utilizar em suas campanhas de Google Ads."
      />
      <S.PagesCaptureDescriptionBlock>
        <S.PagesCaptureDescriptionTitle>
          Seu Ebook está pronto para download
        </S.PagesCaptureDescriptionTitle>
        <S.PagesCaptureDescriptionText>
          Obrigado por confirmar seu email, agora você precisa apenas clicar no
          botão ao lado e baixar o seu ebook e começar a usufruir dos benefícios
          de ter campanhas com palavras chaves negativas que afastem cliques não
          qualificados.
        </S.PagesCaptureDescriptionText>
      </S.PagesCaptureDescriptionBlock>
      <S.PagesCaptureFormBlock>
        <S.PagesCaptureFormBlockTitle>
          Baixe Agora seu Ebook Grátis!
        </S.PagesCaptureFormBlockTitle>
        <S.PagesCaptureEbookImg
          fluid={ebook_palavras_chaves_negativas.childImageSharp.fluid}
          alt="Capa do Ebook Palavras Chaves Negativas para suas Campanhas do Google Ads"
        />
        <S.PagesCaptureDownloadEbookButton
          href="https://drive.google.com/file/d/1LFuCGNfrfIu8_jqjPyrvT3HTd3ghEHsy/view?usp=sharing"
          target="_blank"
        >
          Download Agora
        </S.PagesCaptureDownloadEbookButton>
      </S.PagesCaptureFormBlock>
    </LayoutPagesCapture>
  )
}

export default EbookPalavrasChavesNegativasPage
